import React, { useState } from 'react'
import Layout from '../components/layout'
import './contact.scss'
import SEO from '../components/seo'
import Header from '../components/header'
import 'typeface-montserrat'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

export default function Contact() {

    const [formState, setFormState] = useState({
        name: "",
        email: "",
        tel: "",
        message: ""
    })

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
      }

    const handleChange = e => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...formState })
        })
        .then(() => alert("Votre message a bien été envoyé."))
        .catch(error => alert(error));

        e.preventDefault();
    }

    return (
        <div>
            <Layout>
                <SEO title="Accueil | Cité de l'évasion" />
                <Header />

                    <div>
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11154.360516726982!2d5.9124817!3d45.6590522!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3487042e217ea465!2sCit%C3%A9%20de%20l&#39;Evasion!5e0!3m2!1sfr!2sfr!4v1630705736695!5m2!1sfr!2sfr"
                            title="Google Maps Cité de l'évasion"
                            width="100%"
                            height="500"
                            frameBorder="0"
                            style={{border:0}}
                            allowfullscreen=""
                            aria-hidden="false">
                        </iframe>
                    </div>
                    <section>
                        <div className="formulaire_contact width_1200">
                            <div className="bloc_1">
                                <h2>Contactez-nous</h2>
                                <a href="tel:+33479353242" className="tel_contact"><FontAwesomeIcon icon={faPhone} />04 79 35 32 42</a>
                                <div className="separator"></div>
                                <p>Remplissez le formulaire et laissez nous vous aider</p>
                                <form id="id_form_contact" className="form_contact" name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" onSubmit={handleSubmit}>
                                    <input
                                        type="hidden"
                                        name="form-name"
                                        value="formulaire"
                                    />
                                    <div className="input_contact half">
                                        <input
                                            id="name"
                                            type="text"
                                            name="name"
                                            onChange={handleChange}
                                            value={formState.name}
                                            placeholder="Nom et Prénom *"
                                            required
                                        /> 
                                    </div>
                                    <div className="input_contact half">
                                        <input
                                            id="email"
                                            type="email"
                                            name="email"
                                            onChange={handleChange}
                                            value={formState.email}
                                            placeholder="Email *"
                                            required
                                        />
                                    </div>
                                    <div className="message">
                                        <textarea
                                            className="message_contact"
                                            name="message"
                                            placeholder="Message *"
                                            onChange={handleChange}
                                            required>
                                        </textarea>
                                    </div>
                                    <button type="submit" className="button_lien">Envoyer</button>
                                </form>
                            </div>
                        </div>
                    </section>

            </Layout>
        </div>
    )
}